import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

const Seo = ({ robots, description, lang, meta, title, keywords, og, micromarking }) => {
    const ogImage = og?.image && og?.image !== '0' ? og?.image : 'https://www.genuinewriting.com/img/og_image.png'
    const ogType = og?.type || 'website'

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={`%s | GenuineWriting.com`}
            meta={[
                {
                    name: `robots`,
                    content: robots ? robots: 'index, follow',
                },
                {
                    name: `keywords`,
                    content: keywords,
                },
                {
                    name: `description`,
                    content: description,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: description,
                },
                {
                    property: `og:type`,
                    content: ogType,
                },
                {
                    property: `og:image`,
                    content: ogImage,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                // {
                //     name: `twitter:creator`,
                //     content: site.siteMetadata?.author || ``,
                // },
                {
                    name: `twitter:image`,
                    content: ogImage,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: description,
                },
            ].concat(meta)}
        >
            { !!micromarking && <script type="application/ld+json">
                { micromarking }
            </script>}
        </Helmet>
    )
}

Seo.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
}

Seo.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    image: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
    micromarking: PropTypes.string
}

export default Seo
