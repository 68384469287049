import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import PopupCookies from "./popup/PopupCookies"
// import { IntercomProvider, useIntercom } from 'react-use-intercom'
import { FloatingContacts } from '../../plugins/floating-contacts/floatingContacts'




import "../styles/index.scss"

import Header from "./Header"
import Footer from "./Footer"

const Layout = ({ children }) => {
    useEffect(() => {
        const INTERCOM_APP_ID = 'vjnc7kia'
            setTimeout(() => {
                const floatingContacts = new FloatingContacts(
                    INTERCOM_APP_ID,
                    '#1161e0',
                    [
                        { name: 'Live chat', isLink: false, icon: 'livechat', id: 'livechat-item' }
                        // { name: '1-888-823-9060', isLink: true, href: 'tel:18888239060', icon: 'phone' },
                        // { name: '1-302-351-4405', isLink: true, href: 'tel:13023514405', icon: 'phone' },
                        // { name: '1-812-284-0883', isLink: true, href: 'tel:18122840883', icon: 'fax' }
                        // { name: 'FB Messenger', isLink: true, href: 'https://m.me/customwritingscom', icon: 'facebook' },
                        // { name: 'WhatsApp', isLink: true, href: 'https://api.whatsapp.com/send?phone=18556554828', icon: 'whatsapp' }
                    ]
                )
                floatingContacts.render()
            }, 5000)
    }, [])
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    return (
        <>
            <div className="page">
                <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
                    <div className="page-wrapper">
                        <main>{children}</main>
                    </div>
                    <PopupCookies />
                <Footer />
            </div>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
