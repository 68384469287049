import * as React from "react"
// import { Link } from "gatsby"
import FooterSprite from "../images/sprites/footer_sprite.svg"
// import trustpilot from "../images/png/trust-pilot.png"
// import FB from "../images/facebook.svg"

const Footer = () => (
    <footer className="footer">
        <div className="wrapper footer-wrapper">
            <div className="footer-base-link">
                <div className="footer-resources">
                    <h5 className="footer-title">About</h5>
                    <div className="footer-links">
                        {/* <Link to="/writing-services" className="footer-link">Writing services</Link>
                        <Link to="/testimonials" className="footer-link">Client Testimonials</Link>
                        <Link to="/order/quote" className="footer-link">Prices</Link>
                        <Link to="/info/discounts" className="footer-link">Discounts</Link> */}

                        <a href="/writing-services" className="footer-link">Writing Services</a>
                        <a href="/testimonials" className="footer-link">Client Testimonials</a>
                        <a href="/order/quote" className="footer-link">Prices</a>
                        <a href="/info/discounts" className="footer-link">Discounts</a>
                        <a href="/info/samples" className="footer-link">Samples</a>
                    </div>
                </div>

                <div className="footer-resources">
                    <h5 className="footer-title">Resources</h5>
                    <div className="footer-links">
                        <a href="/support/faq" className="footer-link">FAQ</a>
                        <a href={`${process.env.SHOP_API}/terms`} className="footer-link">Terms Of Service</a>
                        <a href={`${process.env.SHOP_API}/privacy`} className="footer-link">Privacy Policy</a>
                    </div>
                </div>

                <div className="footer-resources">
                    <h5 className="footer-title">Shortcuts</h5>
                    <div className="footer-links">
                        <a href={`${process.env.SHOP_API}/order/new`} className="footer-link">New Order</a>
                        <a href={`${process.env.SHOP_API}/support/ticket/new`} className="footer-link">Support Center</a>
                    </div>
                </div>

                <div className="footer-support">
                    <div className="footer-title">We are open!</div>
                    <p className="footer-description">Our support team is here 24/7 via live chat, phone and on-line support ticketing system.</p>
                    <a href={`${process.env.SHOP_API}/support/ticket/new`} className="button button-primary">ask a question</a>
                </div>
            </div>

            {/* <div className="footer-social">
                <div className="footer-title">Follow us</div>
                <div className="footer-social__wrapper">
                    <div className="footer-social__img">
                        <a href="https://www.facebook.com/essaymeister" target="_blank" rel="nofollow noreferrer ">
                            <svg height="45" width="45">
                                <use href={`${FooterSprite}#3`} />
                            </svg>
                        </a>
                    </div>
                    <div className="footer-social__img">
                        <a href="https://www.instagram.com/genuinewriting" target="_blank" rel="nofollow noreferrer ">
                            <svg height="45" width="45">
                                <use href={`${FooterSprite}#4`} />
                            </svg>
                        </a>
                    </div>
                    <div className="footer-social__img">
                        <a href="https://twitter.com/essay_meister" target="_blank" rel="nofollow noreferrer ">
                            <svg height="45" width="45">
                                <use href={`${FooterSprite}#6`} />
                            </svg>
                        </a>
                    </div>
                </div>
            </div> */}

            {/* <div className="footer-rating">
                <div className="footer-title">Rating</div>
                <div className="footer-rating-wrapper">
                    <a href="https://www.trustpilot.com/review/genuinewriting.com" target="_blank" rel="nofollow noreferrer ">
                        <img src={trustpilot} alt="trustpilot" width={24} height={24} />
                        <span>3.7 / 5</span> <span>Trustpilot</span>
                    </a>
                    <a href="https://www.facebook.com/essaymeister" target="_blank" rel="nofollow noreferrer ">
                        <img src={FB} alt="facebook" width={24} height={24} />
                        <span>4.3 / 5</span> <span>Facebook</span>
                    </a>
                </div>
            </div> */}

            {/* <div className="footer-payments">
                <div className="footer-title">Payments Accepted</div>
                <div className="footer-payments__images">
                    <div className="footer-payments__img">
                        <svg height="30" width="65">
                            <use xlink:href={`${FooterSprite}#0`} />
                        </svg>
                    </div>
                    <div className="footer-payments__img">
                        <svg height="30" width="40">
                            <use xlink:href={`${FooterSprite}#1`} />
                        </svg>
                    </div>
                    <div className="footer-payments__img">
                        <svg height="30" width="62">
                            <use xlink:href={`${FooterSprite}#2`} />
                        </svg>
                    </div>
                </div>
            </div> */}


        </div>
        <div className="wrapper footer-copyright">
            {new Date().getFullYear()}
            <span>Genuinewriting.com</span>
        </div>
    </footer>
)

export default Footer
