import { CreateTemplate } from './floatingContactsTemplate'

export class FloatingContacts {
    constructor(appId, hexColor, contactList) {
        this.template = new CreateTemplate(hexColor, contactList).getTemplate()
        this.APP_ID = appId
    }

    includeIntercomScript(callback) {
        const scriptSrc = `https://widget.intercom.io/widget/${this.APP_ID}`
        const firstScript = document.getElementsByTagName('script')[0]
        if (firstScript.src !== scriptSrc) {
            const intercomScript = document.createElement('script')
            intercomScript.async = true
            intercomScript.src = scriptSrc
            firstScript.parentNode.insertBefore(intercomScript, firstScript)
            intercomScript.addEventListener('load', callback)
        } else {
            callback()
        }
    }

    // addEventShowList() {
    //     const button = this.template.querySelector('.live-chat_btn')
    //     const list = this.template.querySelector('.live-chat_list')
    //     button.addEventListener('click', () => {
    //         button.classList.toggle('live-chat_btn--active')
    //         list.classList.toggle('live-chat_list--open')
    //     })
    // }

    addEventStartIntercom() {
        const intercom = this.template.querySelector('.live-chat_btn')
        const placeholder = this.template.querySelector('.live-chat--placeholder')
        intercom.addEventListener('click', () => {
            window.intercomSettings = {
                app_id: 'vjnc7kia',
                custom_launcher_selector: '#my_custom_link',
                hide_default_launcher: true
            }
            placeholder.style.display = 'block'
            this.includeIntercomScript(() => {
                window.Intercom('show')
                window.Intercom('onShow', function() { placeholder.style.display = 'none' })
            })
        })
    }

    render() {
        const body = document.querySelector('body')
        // this.addEventShowList()
        this.addEventStartIntercom()
        body.appendChild(this.template)
    }
}
