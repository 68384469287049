import React, { useState, useRef, useEffect } from 'react'
import menu from '../content/menu.json'
import NavbarFirstLevel from './NavbarFirstLevel';

const Navbar = () => {
    let [activeMenu, setActiveMenu] = useState(false);
    
    const menuRef = useRef()
    
    useEffect(()=>{
        const clickOutside = (e) => {
            if(menuRef && !menuRef.current.contains(e.target)){
                setActiveMenu(false)
            }
        }
        document.addEventListener('click', clickOutside)
        return function cleanup() {
            document.removeEventListener('click', clickOutside)
        }
    }, [])

    return (
        <nav
            className="navbar"
            ref={menuRef}
        >
            <div
                aria-hidden
                className={activeMenu ? 'navbar-burger active' : 'navbar-burger'}
                onClick={() => setActiveMenu(!activeMenu)}
            >
                <div className="navbar-burger__lines" />
            </div>
            <ul className="navbar-list">
                {menu.map((item, index) => {
                    return(
                        <NavbarFirstLevel
                            key={index}
                            item={item}
                            index={index}
                            closeMenu={() => setActiveMenu(!activeMenu)}
                        />
                    )
                })}
                {/* <li className="navbar-item">
                    <Link to="/blog">Blog</Link>
                </li> */}
            </ul>
        </nav>
    )
}

export default Navbar
