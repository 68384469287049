import * as React from "react"
import Account from "../images/account.svg"
import Navbar from "./Navbar"

const Header = () => (
    <header className="header">
        <div className="header-left">
            <a href="/" className="logo"> </a>
            <Navbar />
        </div>
        <div className="header-right">
            <a href={process.env.SHOP_API} className="header-account">
                <img src={Account} alt="Account" className="header-account__img"/>
                <span className="header-account__text">Manage Orders</span>
            </a>
        </div>
    </header>
)

export default Header
