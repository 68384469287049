import React, { useState } from 'react'
import NavbarSecondLevel from './NavbarSecondLevel'

const NavbarFirstLevel = ({ item, index, closeMenu }) => {
    let [firstLevel, setFirstLevel] = useState(false)

    const firstLevelToggler = (e) => {
        e.stopPropagation();
        setFirstLevel(!firstLevel)
    }

    return (
        item.url ?
        <li
            role={'presentation'}
            className="navbar-item"
            key={index}
            onClick={closeMenu}
        >
            {/* <Link to={item.url}>{item.title}</Link> */}
            <a href={item.url} title={item.title}>{item.title}</a>
        </li>
        :
        <li
            className={firstLevel ? 'navbar-item navbar-item-first active' : 'navbar-item navbar-item-first'}
            key={index}
            role={'presentation'}
            onClick={firstLevelToggler}
        >
            <span className="navbar-item__arrow">{item.title}</span>
            <span className="navbar-nesting__wrapper">
                <span 
                    className="navbar-nesting__arrow sub-item"
                    role={'presentation'}
                    onClick={firstLevelToggler}
                ></span>
                <ul className="navbar-nesting">
                    {item.items.map((service, index) => {
                        return(
                            <NavbarSecondLevel key={index} service={service} index={index} closeMenu={closeMenu}/>
                        )
                    })}
                </ul>
            </span>
        </li>
    )
}

export default NavbarFirstLevel
