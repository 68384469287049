export class CreateTemplate {
    constructor(hexColor, contactList) {
        this.hexColor = hexColor
        this.contactList = contactList
    }

    createButton() {
        const button = document.createElement('div')
        button.classList.add('live-chat_btn')
        button.appendChild(this.createCloseButton())
        button.appendChild(this.createButtonIcons())
        return button
    }

    createCloseButton() {
        const close = document.createElement('div')
        close.classList.add('live-chat_btn--close')
        return close
    }

    createDots() {
        const dots = document.createElement('span')
        dots.dataset.icon = 'dots'
        for (let i = 0; i < 3; i++) {
            const dot = document.createElement('i')
            dots.appendChild(dot)
        }
        return dots
    }

    createButtonIcons() {
        const icons = document.createElement('div')
        icons.classList.add('live-chat_btn-icons')
        icons.appendChild(this.createDots())

        this.contactList.forEach((element) => {
            const item = document.createElement('span')
            item.dataset.icon = element.icon
            icons.appendChild(item)
        })

        return icons
    }

    createContactList() {
        const list = document.createElement('ul')
        list.classList.add('live-chat_list')
        this.contactList.forEach((element) => {
            const item = document.createElement('li')
            item.classList.add('live-chat_item')
            item.dataset.icon = element.icon
            if (element.isLink) {
                const link = document.createElement('a')
                link.textContent = element.name
                link.href = element.href
                item.appendChild(link)
            } else {
                item.textContent = element.name
            }
            if (element.id) {
                item.id = element.id
            }
            list.appendChild(item)
        })
        return list
    }

    createPlaceholder() {
        const placeholder = document.createElement('div')
        placeholder.classList.add('live-chat--placeholder')
        return placeholder
    }

    getTemplate() {
        const wrapper = document.createElement('div')
        wrapper.classList.add('live-chat')
        wrapper.appendChild(this.createPlaceholder())
        wrapper.appendChild(this.createButton())
        wrapper.appendChild(this.createContactList())
        return wrapper
    }
}
