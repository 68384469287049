import React, { useState } from 'react'

const NavbarSecondLevel = ({ service, index, closeMenu }) => {
    let [secondLevel, setSecondLevel] = useState(false)

    const secondLevelToggler = (e) => {
        e.stopPropagation();
        setSecondLevel(!secondLevel)
    }
    return(
        service.url ?
        <li 
            className="navbar-nesting__item"
            role={'presentation'}
            key={index}
            onClick={closeMenu}
        >
            {/* <Link className="sub-item" to={service.url}>{service.title}</Link> */}
            <a href={service.url} className="sub-item" title={service.title}>{service.title}</a>
        </li>
        :
        <li 
            className={secondLevel ? 'navbar-nesting__item sub-item navbar-item__arrow active' : 'navbar-nesting__item sub-item navbar-item__arrow'}
            key={index}
            role={'presentation'}
            onClick={secondLevelToggler}
        >
            {service.title}
            <div className="navbar-subnesting__wrapper">
                <div
                    className="navbar-subnesting__arrow sub-item"
                    role={'presentation'}
                    onClick={secondLevelToggler}
                ></div>
                <ul className="navbar-subnesting">
                    {service.items.map((sublink, index) => {
                        return (
                            <li className="navbar-subnesting__item" key={index}>
                                {/* <Link className="sub-item" to={sublink.url}>{sublink.title}</Link> */}
                                <a href={sublink.url} className="sub-item" title={sublink.title}>{sublink.title}</a>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </li>
    )
}

export default NavbarSecondLevel
