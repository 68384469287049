import React, {useEffect, useState} from "react"


const PopupCookies = () => {
    useEffect(() => {
        setTimeout(() => {
            setCookieExists(getCookie(cookieName) === cookieValue)
        }, 5000)
    }, [])

    const cookieName = '_service_gdpr'
    const cookieValue = 'accepted'
    const cookieDays = 30
    const [cookieExists, setCookieExists] = useState(true)

    const getCookie = (cname) => {
        const name = cname + '='
        const decodedCookie = decodeURIComponent(document.cookie)
        const ca = decodedCookie.split(';')
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i]
            while (c.charAt(0) === ' ') {
                c = c.substring(1)
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length)
            }
        }
        return ''
    }
    const setCookie = (cname, cvalue, exdays) => {
        const d = new Date()
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
        const expires = 'expires=' + d.toUTCString()
        document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
    }
    const accept = () => {
        setCookieExists(true)
        setCookie(cookieName, cookieValue, cookieDays)
    }
    return (
        <div>
            {!cookieExists &&
                <div className="cookie">
                    <div className="cookie-text">
                        We use cookies to analyze traffic and improve your experience. Just wanted to let you know!
                    </div>
                    <div className="cookie-action">
                        <span className="button button-primary" role={'presentation'} onClick={() => accept()}>
                            Accept <span className="hidden">&nbsp; &amp; Continue</span>
                        </span>
                    </div>
                </div>
            }
        </div>
    )
}

export default PopupCookies
